<template>
  <div class="user-status">
    <div class="user-status__top">
      <div class="user-status__top-select">
        <div class="select-container" :class="open_detalle_select && 'active'">
          <div class="select-btn" @click="openSelectDetalle">
            <div class="item">
              {{ detalle_selected.name }}
            </div>
            <div class="icon">
              <font-awesome-icon
                :icon="open_detalle_select ? 'angle-up' : 'angle-down'"
              >
              </font-awesome-icon>
            </div>
          </div>
          <div class="select-list" v-if="open_detalle_select">
            <div
              v-for="(indi, k) in detalle_list"
              :key="k"
              class="list-item"
              @click="selectDetalle(indi)"
            >
              {{ indi.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="user-status-top-filter">
        <div class="filter-text text-secondary">Filtrar por periodo:</div>
        <div class="inicio">
          <div class="calendar-btn" @click="openCalendar('inicio')">
            {{ date_inicio }}
            <div class="icon">
              <font-awesome-icon icon="calendar-day"></font-awesome-icon>
            </div>
          </div>
          <div class="calendar" v-if="show_calendar == 'inicio'">
            <div class="border-arrow">
              <div class="arrow"></div>
            </div>
            <v-date-picker
              locale="es"
              color="blue"
              class="date"
              :key="key_calendar_inicio"
              :min-date="min_date"
              :max-date="max_date"
              @dayclick="onDayClick"
              v-model="calendar_inicio"
            />
            <div
              class="delete-date"
              @click="
                (date_termino = 'Término'),
                  (date_inicio = 'Inicio'),
                  getUsersList(),
                  (show_calendar = false)
              "
            >
              Eliminar
            </div>
          </div>
        </div>
        <div class="termino">
          <div class="calendar-btn" @click="openCalendar('termino')">
            {{ date_termino }}
            <div class="icon">
              <font-awesome-icon icon="calendar-day"></font-awesome-icon>
            </div>
          </div>
          <div class="calendar" v-if="show_calendar == 'termino'">
            <div class="border-arrow">
              <div class="arrow"></div>
            </div>
            <v-date-picker
              locale="es"
              color="blue"
              class="date"
              :key="key_calendar_termino"
              :min-date="min_date"
              :max-date="max_date"
              @dayclick="onDayClick"
              v-model="calendar_termino"
            />
            <div
              class="delete-date"
              @click="
                (fecha_fin = 'Término'),
                  (fecha_inicio = 'Inicio'),
                  getAdminListBenefit(),
                  (show_calendar = false)
              "
            >
              Eliminar
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="user-status__container">
      <div class="user-status__container-left">
        <div class="user-status__container-left-searcher">
          <div class="searcher-left">Realiza una búsqueda</div>
          <div class="searcher-right">
            <div class="searcher">
              <div
                class="searcher-container"
                :class="show_search_result && 'active'"
              >
                <input
                  type="text"
                  placeholder="Ingresa nombre usuario"
                  v-model="search_input"
                />
                <div class="icon" @click="openUserDetail()">
                  <font-awesome-icon icon="search"></font-awesome-icon>
                </div>
              </div>
              <ul class="searcher-list" v-if="show_search_result">
                <li
                  v-for="(user, k) in search_list"
                  :key="k"
                  
                >
                  {{ user.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="user-status__container-left-head">
          <div class="title name">Nombre usuario</div>
          <div class="title date">Fecha última dosis</div>
          <div class="title status"></div>
        </div>
        <div class="hr"></div>
        <div class="user-status__container-left-body">
          
          <div class="row" v-for="(user, i) in users" :key="i">
            <div class="user" @click="selectUser(user)">
              <div class="user-name">
                <div class="img">
                  <img
                    :src="handleUserImage(user.usuario.imagen_perfil)"
                    alt=""
                  />
                </div>
                <div class="info">
                  <div class="name">
                    {{ user.usuario.primer_nombre }}
                    {{ user.usuario.apellido_paterno }}
                  </div>
                  <div class="cargo">
                    {{ user.usuario.cargo[0].nombre_cargo }}
                  </div>
                </div>
              </div>
              <div class="user-date" v-if="detalle_selected.id == 1">
                {{
                  user.vacunas.length == 0
                    ? "Sin registros"
                    : dateFormat(
                        user.vacunas[user.vacunas.length - 1].fecha_dosis
                      )
                }}
              </div>
              <div class="user-status" v-if="detalle_selected.id == 1">
                <div class="user-status__container detail">
                  {{ user.vacunas.length == 0 ? "" : "Ver detalle" }}
                </div>
              </div>
              <div class="user-date" v-if="detalle_selected.id == 2">
                {{
                  user.reportes.length == 0
                    ? "Sin registros"
                    : dateFormat(
                        user.reportes[user.reportes.length - 1].fecha_creacion
                      )
                }}
              </div>
              <div class="user-status" v-if="detalle_selected.id == 2">
                <div class="user-status__container detail">
                  {{ user.reportes.length == 0 ? "" : "Ver detalle" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="user-status__container-right">
        <div class="title">Información del usuario</div>
        <div class="right-container">
          <div class="user-detail">
            <div
              class="user-detail-img"
              v-if="selected_user != null && selected_user.vacunas != undefined"
            >
              <div
                class="img-container"
                v-for="(dose, k) in dose_list"
                :key="k"
              >
                <div class="dose-text">
                  <div class="dose-text-title">
                    {{ dose.numero_dosis }} de
                    {{ dose.tipo_vacuna.nombre }} administrada el
                    {{ dateFormat(dose.fecha_dosis) }}
                  </div>
                </div>
                <img :src="dose.url_imagen" alt="" />
              </div>
            </div>
            <div
              class="user-detail-rep"
              v-if="
                selected_user != null && selected_user.reportes != undefined
              "
            >
              <div class="container" v-for="(rep, k) in reports_list" :key="k">
                <div class="rep-text">
                  <div class="rep-text-title">
                    <div>Reporte de sintomas</div>
                    <div class="rep-text-title-name">
                      {{ rep.respuestas[0].respuesta }}
                    </div>
                  </div>
                  <div class="rep-text-date">
                    {{ dateFormat(rep.fecha_creacion) }}
                  </div>
                  <div
                    class="rep-text-img"
                    @click="(report_data = rep), (open_modal_report = true)"
                  >
                    <font-awesome-icon icon="eye"></font-awesome-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="respuestas-modal" v-if="open_modal_report">
      <div class="respuestas-modal__container">
        <div class="back" @click="open_modal_report = false">
          <font-awesome-icon icon="times"> </font-awesome-icon>
        </div>
        <div class="respuestas-modal__container-title">Respuestas Usuario</div>
        <div class="respuestas-modal__container-body">
          <div class="content">
            <div
              class="item"
              v-for="(report, i) in report_data.respuestas"
              :key="i"
            >
              <div class="item-num">{{ report.pregunta.id_pregunta }}</div>
              <div class="item-question">
                {{ report.pregunta.texto_pregunta }}
              </div>
              <div class="item-response">{{ report.respuesta }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Status
      v-if="open_modal_status"
      :msg="modal_status_msg"
      :status="modal_status"
      @close="closeStatus"
    />
    <Spinner v-if="show_spinner" />
  </div>
</template>

<script>
import Status from "../Modales/Status.vue";
import {
  LIST_TYPE_VACUNAS,
  LIST_ALL_VACUNAS,
  ENCUSTAS_COVID,
  LIST_REPORTS_COVID,
} from "@/apollo/queries";
import Spinner from "../Spinner.vue";
export default {
  components: { Status, Spinner },
  data() {
    return {
      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
      detalle_list: [
        { name: "Detalle dosis", id: 1 },
        { name: "Detalle reporte de síntomas", id: 2 },
      ],
      detalle_selected: { name: "Detalle dosis", id: 1 },
      open_detalle_select: false,
      user_default_img: require("@/assets/img/user_default_img.svg"),
      key_calendar_inicio: 0,
      key_calendar_termino: 0,
      calendar_inicio: new Date(),
      calendar_termino: new Date(),
      show_calendar: null,
      max_date: new Date(),
      min_date: "",
      open_modal_status: false,
      open_modal_report: false,
      modal_status_msg: false,
      modal_status: true,
      date_inicio: "Inicio",
      date_termino: "Término",
      reports_list: [],
      report_data: {},
      dose_list: [],
      ejemplo_esquema_img: require("@/assets/img/ejemplo_esquema_vacunacion.png"),
      search_list: [
        {
          id: 1,
          name: "Usuario 1",
        },
        {
          id: 2,
          name: "Usuario 2",
        },
      ],
      show_search_result: false,
      show_spinner:false,
      search_input: "",
      search_input_ok: "",
      users: [],
      vacunas: [],
      selected_user: null,
    };
  },
  computed: {},
  mounted() {
    
    this.getUsersList();
    this.getVacunasList();
  },
  watch: {
    search_input: function () {
      if (this.search_input.length > 2) {
        this.search_input_ok = this.search_input;
        this.getUsersList();
      } else {
        if (this.search_input_ok != "") {
          this.search_input_ok = "";
          this.getUsersList();
        }
      }
    },
  },
  methods: {
    selectUser(user) {
      this.selected_user = user;

      if (this.detalle_selected.id == 1) {
        this.dose_list = user.vacunas;
        this.dose_list.sort(function (a, b) {
          return (
            new Date(a.fecha_dosis).getTime() -
            new Date(b.fecha_dosis).getTime()
          );
        });
      }
      if (this.detalle_selected.id == 2) {
        this.reports_list = user.reportes;
        this.reports_list.sort(function (a, b) {
          return (
            new Date(a.fecha_creacion).getTime() -
            new Date(b.fecha_creacion).getTime()
          );
        });
      }
    },
   async getVacunasList() {
      var vars = {
        id_empresa: this.id_empresa,
      };
      await this.$apollo
        .query({
          query: LIST_TYPE_VACUNAS,
          variables: vars,
        })
        .then((res) => {
          var resp = res.data.ListTipoVacunas;
          this.vacunas = resp;
          //console.table(resp);
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    async getUsersList() {
      this.show_spinner = true;
      //console.log("date_inicio:",this.date_termino,this.date_inicio)
      this.users = [];
      var vars = {
        id_empresa: this.id_empresa,
        nombre_usuario: this.search_input_ok,
        fecha_inicio: this.date_inicio == "Inicio" ? "" : this.date_inicio,
        fecha_final: this.date_termino == "Término" ? "" : this.date_termino,
      };
      console.log('vars', vars);
      await this.$apollo
        .query({
          query: LIST_ALL_VACUNAS,
          variables: vars,
        })
        .then((res) => {
          var resp = res.data.ListaAllVacunas;
          this.users = resp;
          this.show_spinner = false;
          //console.table(resp);
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getUsersReportsList() {
      this.users = [];
      var vars = {
        id_empresa: this.id_empresa,
        id_encuesta: "1",
        nombre_usuario: this.search_input_ok,
        fecha_inicio: "",
        fecha_final: "",
      };
      this.$apollo
        .query({
          query: LIST_REPORTS_COVID,
          variables: vars,
        })
        .then((res) => {
          var resp = res.data.ListAllReportesUser;
          this.users = resp;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    getUsersEncuestasList() {
      this.users = [];
      var vars = {
        id_empresa: this.id_empresa,
        nombre_usuario: this.search_input_ok,
        fecha_inicio: "",
        fecha_final: "",
      };
      this.$apollo
        .query({
          query: ENCUSTAS_COVID,
          variables: vars,
        })
        .then((res) => {
          var resp = res.data.ListaAllVacunas;
          this.users = resp;
          console.table(resp);
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    openSelectDetalle() {
      this.show_calendar = null; //cerrar calendario
      this.open_detalle_select = !this.open_detalle_select;
    },
    selectDetalle(detalle) {
      this.selected_user = null;
      this.detalle_selected = detalle;
      this.open_detalle_select = false;
      if (detalle.id == 1) {
        this.getUsersList();
      }
      if (detalle.id == 2) {
        this.getUsersReportsList();
      }
    },
    handleUserImage(img) {
      if (img !== null && img !== "" && img !== undefined) {
        return img;
      } else {
        return this.user_default_img;
      }
    },
    openCalendar(cal) {
      this.open_estado_select_list = false;
      this.open_detalle_select = false;
      if (this.show_calendar == cal) {
        this.show_calendar = null;
      } else {
        this.show_calendar = cal;
      }
    },
    onDayClick(day) {
      if (this.show_calendar == "inicio") {
        this.date_inicio = day.id;
      }
      if (this.show_calendar == "termino") {
        this.date_termino = day.id;
      }
      //console.log("fechas", this.date_termino, this.date_termino);
      if (this.date_termino != "Inicio" && this.date_termino != "Término") {
        this.getUsersList();
      }

      setTimeout(() => {
        this.show_calendar = null;
      }, 500);
    },
    closeStatus() {
      this.open_modal_status = false;
    },
    saveChanges() {
      this.open_modal_status = true;
      this.modal_status_msg = "Cambios guardados con éxito";
    },
    openUserDetail() {
      this.getUsersList();
    },
    dateFormat(date) {
      var day = new Date(date);
      var day_d = day.getDate() + 1;
      var day_m = day.getMonth() + 1;
      var day_y = day.getFullYear();
      return `${day_d > 9 ? day_d : "0" + day_d}/${
        day_m > 9 ? day_m : "0" + day_m
      }/${day_y}`;
    },
  },
};
</script>
